import * as React from 'react'
import { useRouter } from 'next/navigation'

import { cn } from '@/lib/utils'

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  wrapperClass?: string
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  href?: string
  target?: '_blank' | '_self'
}

const Table = React.forwardRef<HTMLTableElement, TableProps>(
  ({ className, wrapperClass, ...props }, ref) => (
    <div className={cn('overflow-x-auto', wrapperClass)}>
      <table
        ref={ref}
        className={cn('w-full caption-top text-sm ', className)}
        {...props}
      />
    </div>
  ),
)
Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border-b', className)} {...props} />
))
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0', className)}
    {...props}
  />
))
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(' bg-muted font-medium', className)}
    {...props}
  />
))
TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<HTMLTableRowElement, RowProps>(
  ({ className, href, onClick, target, ...props }, ref) => {
    const router = useRouter()
    return (
      <tr
        ref={ref}
        className={cn(
          'border-b border-default-300 transition-colors  data-[state=selected]:bg-muted hover:bg-opacity-10 hover:bg-slate-100 hover:cursor-pointer',
          {
            'hover:bg-gray-400 hover:cursor-pointer': href || onClick,
            'hover:cursor-default': !href && !onClick,
          },
          className,
        )}
        {...props}
        onClick={(e) => {
          if (onClick) {
            onClick(e)
          }
          if (!href) {
            return
          }
          if (target === '_blank') {
            window.open(href, '_blank')
          } else {
            router.push(href)
          }
        }}
      />
    )
  },
)
TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-14 px-4 text-left align-middle font-semibold text-sm text-linkby-label capitalize [&:has([role=checkbox])]:pr-0',
      className,
    )}
    {...props}
  />
))
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'p-2 align-middle  text-sm text-default-600 font-normal  [&:has([role=checkbox])]:pr-0',
      className,
    )}
    {...props}
  />
))
TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn(
      'mb-4 text-sm font-medium text-default-700 text-start',
      className,
    )}
    {...props}
  />
))
TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
}
