import { Icon } from '@iconify/react'

import { StatusPillColor } from '@/lib/type'

import { Badge } from './badge'

const StatusPillStyles: Record<
  StatusPillColor,
  { backgroundStyle: string; textStyle: string }
> = {
  default: {
    backgroundStyle: 'bg-gray-200',
    textStyle: 'text-gray-700',
  },
  warning: {
    backgroundStyle: 'bg-yellow-200',
    textStyle: 'text-yellow-700',
  },
  success: {
    backgroundStyle: 'bg-green-200',
    textStyle: 'text-green-700',
  },
  destructive: {
    backgroundStyle: 'bg-red-200',
    textStyle: 'text-red-700',
  },
  info: {
    backgroundStyle: 'bg-blue-200',
    textStyle: 'text-blue-700',
  },
}

export interface StatusPillOptions {
  size?: 'xs' | 'sm' | 'md'
  className?: string
  backgroundStyle?: string
  textStyle?: string
  children: React.ReactNode
  color?: StatusPillColor
}

const sizeStyle = {
  xs: {
    badge: `px-1.5 py-2px`,
    text: `text-xs`,
    icon: {
      height: '1em',
      width: '1em',
    },
  },
  sm: {
    badge: `px-2 py-0`,
    text: `text-sm`,
    icon: {
      height: '1em',
      width: '1em',
    },
  },
  md: {
    badge: `px-3 py-1`,
    text: `text-md`,
    icon: {
      height: '1.2em',
      width: '1.2em',
    },
  },
}

const StatusPill: React.FC<StatusPillOptions> = ({
  backgroundStyle,
  className,
  textStyle,
  children,
  color,
  size = 'sm',
}) => {
  if (color && !backgroundStyle) {
    backgroundStyle = StatusPillStyles[color].backgroundStyle
  }
  if (color && !textStyle) {
    textStyle = StatusPillStyles[color].textStyle
  }

  return (
    <Badge
      className={`status-pill ${sizeStyle[size].badge} inline-flex items-center rounded-xl ${backgroundStyle} ${className}`}>
      <Icon
        className={`${sizeStyle[size].text} ${textStyle} w-4`}
        icon="prime:circle-fill"
        height={sizeStyle[size].icon.height}
        width={sizeStyle[size].icon.height}
      />
      <span className={`${sizeStyle[size].text} ${textStyle}`}>{children}</span>
    </Badge>
  )
}

export default StatusPill
