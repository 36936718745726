import { CampaignStatusEnum } from '@/enums/campaign.enum'
import { statuses } from '@/constants/campaign.constant'

import StatusPill, { StatusPillOptions } from '@/components/ui/status-pill'

const CampaignStatusPills = {
  [CampaignStatusEnum.Draft]: {
    text: statuses[CampaignStatusEnum.Draft],
    backgroundStyle: 'bg-gray-200',
    textStyle: 'text-gray-700',
  },
  [CampaignStatusEnum.Pending]: {
    text: statuses[CampaignStatusEnum.Pending],
    backgroundStyle: 'bg-yellow-200',
    textStyle: 'text-yellow-700',
  },
  [CampaignStatusEnum.Active]: {
    text: statuses[CampaignStatusEnum.Active],
    backgroundStyle: 'bg-green-200',
    textStyle: 'text-green-700',
  },
  [CampaignStatusEnum.Rejected]: {
    text: statuses[CampaignStatusEnum.Rejected],
    backgroundStyle: 'bg-red-200',
    textStyle: 'text-red-700',
  },
  [CampaignStatusEnum.Finished]: {
    text: statuses[CampaignStatusEnum.Finished],
    backgroundStyle: 'bg-blue-200',
    textStyle: 'text-blue-700',
  },
}

const CampaignStatus = ({
  className = '',
  status,
  size,
  label,
}: {
  status: CampaignStatusEnum
  className?: string
  size?: StatusPillOptions['size']
  label?: string
}) => {
  const statusConfig = CampaignStatusPills[status]

  if (!statusConfig) {
    return <></>
  }

  return (
    <StatusPill
      size={size}
      className={className}
      backgroundStyle={statusConfig.backgroundStyle}
      textStyle={statusConfig.textStyle}>
      {label || statusConfig.text}
    </StatusPill>
  )
}

export default CampaignStatus
